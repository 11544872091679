import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from 'cfa-react-components';

const EditTask = ({ task, setTask, error, onEdit }) => {
  const { t } = useTranslation();
  const nameRef = useRef(null);
  const notesRef = useRef(null);
  const linkRef = useRef(null);

  const maxLength = 250;

  return (
    <StyledEditTaskProcedureContainer>
      <Typography variant="overline3">{t('Generic.task')}</Typography>
      <TaskTextField
        fullWidth
        maxLength={maxLength}
        onChange={e => setTask({ ...task, name: e.target.value })}
        onKeyDown={e => e.key === 'Enter' && notesRef.current.focus()}
        placeholder={t('TrainingPlans.addTaskFormPlaceholderText.task')}
        ref={nameRef}
        value={task.name}
      />
      <Typography variant="overline3">{t('Generic.notes')}</Typography>
      <TaskTextField
        fullWidth
        onChange={e => setTask({ ...task, note: e.target.value })}
        onKeyDown={e => e.key === 'Enter' && linkRef.current.focus()}
        placeholder={t('TrainingPlans.addTaskFormPlaceholderText.notes')}
        ref={notesRef}
        value={task.note ?? ''}
      />
      <Typography variant="overline3">{t('Generic.url')}</Typography>
      <TaskTextField
        data-testid="EditTaskUrl"
        error={error}
        errorText={error}
        fullWidth
        onChange={e => setTask({ ...task, url: e.target.value })}
        onKeyDown={e =>
          e.key === 'Enter' && task.name.trim().length > 0 && onEdit()
        }
        placeholder={t('TrainingPlans.addTaskFormPlaceholderText.link')}
        ref={linkRef}
        value={task.url ?? ''}
      />
    </StyledEditTaskProcedureContainer>
  );
};

EditTask.propTypes = {
  task: PropTypes.object.isRequired,
  setTask: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
};

const StyledEditTaskProcedureContainer = styled.div`
  width: 100%;
`;
const TaskTextField = styled(TextField)`
  margin-bottom: 16px;
`;

export default EditTask;
