import { getNameFromLanguage } from 'util/language';
import { getClickableLink } from 'util/url';
import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IconCircleCheckDisabled, IconCircleDisabled } from 'icons';
import {
  IconLink,
  IconNote,
  IconCircle,
  IconCircleCheck,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'cfa-react-components';

const PlanCardTask = ({
  disabled,
  isActive,
  isComplete,
  language,
  onClick,
  onCompleteToggle,
  statuses,
  step,
  user,
}) => {
  const [isCompleted, setIsCompleted] = useState(isComplete);
  const { t } = useTranslation();
  const hasNotes = !isEmpty(step?.note);
  const hasUrls = !isEmpty(step?.urls[0]?.urlSet);
  const canExpand = hasNotes || hasUrls;
  const userId = user?.adId ?? user?.userId ?? undefined;
  const userStatus = statuses
    ?.find(status => status.userId === userId)
    ?.steps?.find(statusStep => statusStep.stepId === step.id);
  const stepDuration = userStatus?.stepDuration
    ? Math.round(userStatus?.stepDuration / 60)
    : 0;

  return (
    <>
      <TaskOrProcedureBody $isActive={isActive}>
        {isCompleted ? (
          <IconWrapper
            $isDisabled={disabled}
            data-testid="StepStatusIcon"
            onClick={
              !disabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!disabled ? <StyledIconCircleCheck /> : <DisabledCompleteIcon />}
          </IconWrapper>
        ) : (
          <IconWrapper
            $isDisabled={disabled}
            data-testid="StepStatusIcon"
            onClick={
              !disabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!disabled ? <StyledIconCircle /> : <DisabledOvalIcon />}
          </IconWrapper>
        )}
        <TypeAndNameWrapper
          $canExpand={canExpand}
          data-testid={`PlanStep${step.id}`}
          onClick={onClick}
        >
          <Typography data-testid="TaskTitle" variant="overline3">
            {t('Generic.task')}
            {isCompleted &&
              user &&
              ` - ${t('TrainingPlans.completedIn')} ${stepDuration} ${t(
                'Generic.mins',
              )}`}
          </Typography>
          <Typography data-testid="TaskOrProcedureName" variant="body1">
            {getNameFromLanguage(step?.name, language)}
          </Typography>
        </TypeAndNameWrapper>
        <TaskIconsWrapper $canExpand={canExpand} onClick={onClick}>
          {getNameFromLanguage(step?.note, language) && <IconNote />}
          {hasUrls && <IconLink />}
        </TaskIconsWrapper>
      </TaskOrProcedureBody>
      {isActive && (
        <>
          {hasNotes || hasUrls ? (
            <NotesAndLinkWrapper>
              {getNameFromLanguage(step?.note, language) && (
                <TaskAndProcedureNotes>
                  <Typography variant="overline3">
                    {t('Generic.notes')}
                  </Typography>
                  <Typography variant="body1">
                    {getNameFromLanguage(step?.note, language)}
                  </Typography>
                </TaskAndProcedureNotes>
              )}
              {hasUrls &&
                step.urls.map((link, index) => (
                  <TaskAndProcedureLink
                    href={getClickableLink(
                      getNameFromLanguage(link.urlSet, language),
                    )}
                    key={`${link.id}-${index}`}
                    target="_blank"
                  >
                    <Typography variant="body1">{t('Generic.link')}</Typography>
                    <TaskAndProcedureLinkDescription variant="body1">
                      {getNameFromLanguage(link.urlSet, language)}
                    </TaskAndProcedureLinkDescription>
                  </TaskAndProcedureLink>
                ))}
            </NotesAndLinkWrapper>
          ) : null}
        </>
      )}
    </>
  );
};

PlanCardTask.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  language: PropTypes.string,
  onClick: PropTypes.func,
  onCompleteToggle: PropTypes.func,
  statuses: PropTypes.array,
  step: PropTypes.object,
  user: PropTypes.object,
};

PlanCardTask.defaultProps = {
  disabled: false,
  isActive: false,
  isComplete: false,
  language: '',
  onClick: () => {},
  onCompleteToggle: () => {},
  statuses: [],
  step: null,
  user: null,
};

const TaskOrProcedureBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding-bottom: ${props => props.$isActive && '10px'};
`;

const IconWrapper = styled.div`
  margin-right: 0.5em;
  cursor: ${props => (!props.$isDisabled ? 'pointer' : 'default')};
`;

const StyledIconCircle = styled(IconCircle)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const StyledIconCircleCheck = styled(IconCircleCheck)`
  color: ${({ theme }) => theme.semanticColors.success};
`;

const DisabledOvalIcon = styled(IconCircleDisabled)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const DisabledCompleteIcon = styled(IconCircleCheckDisabled)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const TypeAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: ${props => props.$canExpand && !props.$isDisabled && 'pointer'};
`;

const TaskIconsWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.grayScale.gray2};
  cursor: ${props => props.$canExpand && !props.$isDisabled && 'pointer'};
`;

const NotesAndLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 41px;
`;

const TaskAndProcedureNotes = styled.div`
  padding-bottom: 10px;
`;

const TaskAndProcedureLink = styled.a`
  padding-bottom: 10px;
`;

const TaskAndProcedureLinkDescription = styled(Typography)`
  color: ${props => props.theme.primaryPalette.navyBlue};
  text-decoration: underline;

  &:hover {
    color: ${props => props.theme.primaryPalette.button.hover};
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default PlanCardTask;
